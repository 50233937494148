import React, { useState } from "react";
import footerStyles from './styles.module.css'
import { Link } from "react-router-dom";
import InputField from "../InputField";
import Button from "../Button";
import { facebook, instagram, linkedin, navLogo, twitter, youtube } from "../../assets";
import { t } from "i18next";

function Footer() {
    const [subMail, setSubMail] = useState('')
    return (
        <footer className={footerStyles.footer}>
        <div className="container">
          <div className={"row justify-content-between g-5 mt-5"}>
            <div className={"d-flex justify-content-between flex-grow-1 col-11 col-lg-6"}>
              <div>
                <p className={footerStyles.footer_section_title}>{t("navigation")}</p>
                <div className={footerStyles.footer_section_links}>
                  <Link className={footerStyles.footer_section_link} to={'home'}>
                    {t("home")}
                  </Link>
                  <Link className={footerStyles.footer_section_link} to={'aboutUs'}>
                    {t("aboutUs")}
                  </Link>
                  <Link className={footerStyles.footer_section_link} to={'/'}>
                    {t("myAccount")}
                  </Link>
                  <Link className={footerStyles.footer_section_link} to={'shop'}>
                    {t("shop")}
                  </Link>
                  <Link className={footerStyles.footer_section_link} to={'contactUs'}>
                    {t("contactUs")}
                  </Link>
                </div>
              </div>
              <div>
                <p className={footerStyles.footer_section_title}>{t("product")}</p>
                <div className={footerStyles.footer_section_links}>
                  <Link className={footerStyles.footer_section_link} to={'/'}>
                    {t("orderHistory")}
                  </Link>
                  <Link className={footerStyles.footer_section_link} to={'/'}>
                    {t("wishlist")}
                  </Link>
                  <Link className={footerStyles.footer_section_link} to={'cart'}>
                    {t("cart")}
                  </Link>
                  <Link className={footerStyles.footer_section_link} to={'/'}>
                    {t("paymentMethod")}
                  </Link>
                </div>
              </div>
              <div>
                <p className={footerStyles.footer_section_title}>{t("company")}</p>
                <div className={footerStyles.footer_section_links}>
                  <Link className={footerStyles.footer_section_link} to={'goldPrice'}>
                    {t("goldPrice")}
                  </Link>
                  <Link className={footerStyles.footer_section_link} to={'goldPriceHistory'}>
                    {t("goldPriceHistory")}
                  </Link>
                  <Link className={footerStyles.footer_section_link} to={'/privacyPolicy'}>
                    {t("privacyPolicy")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-5">
              <p className={footerStyles.footer_subscribe_title}>
                {t("signUpNewsletter")}
              </p>
              <p className={footerStyles.footer_section_link}>
                {t("taxNumber")} <br /> {t("ministrySupply")}
              </p>
            </div>
          </div>
          <div className={footerStyles.horizontal_footer_line} />
          <div className="row justify-content-center justify-content-md-between align-items-center pb-4 gy-4">
            <div className={`${footerStyles.contact_footer_icons} col-11 col-md d-flex justify-content-center`}>
              <div>
                <img src={linkedin} alt="linkedin" className="img" />
              </div>
              <div>
                <img src={twitter} alt="twitter" className="img" />
              </div>
              <div>
                <img src={instagram} alt="instagram" className="img" />
              </div>
              <div>
                <img src={facebook} alt="facebook" className="img" />
              </div>
              <div>
                <img src={youtube} alt="youtube" className="img" />
              </div>
            </div>
            <figure className={`${footerStyles.footer_log_Box} col-11 col-md d-flex justify-content-center`}>
              <img src={navLogo} alt="site-Logo" className='img' />
            </figure>
            <p className={`${footerStyles.copy_right} col-11 col-md d-flex justify-content-center`}>
              © 2024 Trejars. {t("allRightsReserved")}
            </p>
          </div>
        </div>
      </footer>
      
    );
}

export default Footer;
