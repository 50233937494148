import React from "react";
import aboutStyles from './styles.module.css'
import GPStyles from '../GoldPrice/styles.module.css'
import { AppBanner } from "../../components";
import { contactBanner, goldDoctor, neckless, work1, work2, work3 } from "../../assets";
import { t } from "i18next";

function AboutUs() {
  return (
    <main className="bg_dark">
    <div className="mt-lg-4 mb-5">
      <AppBanner url={contactBanner} title={t('aboutUs')} />
    </div>
    {/* starting section */}
    <section>
      <div className="container">
        <div className={`${aboutStyles.start_container} row gx-0`}>
          <div className={`${aboutStyles.start_img_box} hidden col-12 col-lg-6`}>
            <img src={goldDoctor} alt={t('goldInHands')} className='img cover' />
          </div>
          <div className="col-12 col-lg-6 px-4 py-5 py-lg-0 align-content-center">
            <p className={aboutStyles.start_content_how}>{t('howWeStarted')}</p>
            <p className={aboutStyles.start_content_title}>{t('unlockingNewPossibilities')}</p>
            <p className={aboutStyles.start_content_txt}>
              {t('taxRegistrationNumber')} <br /> {t('ministrySupply')} 
            </p>
          </div>
        </div>
      </div>
    </section>
    {/* work section */}
    <section className={aboutStyles.work_section}>
      <div className="container">
        <div className={GPStyles.prices_title_box}>
          <h3 className={GPStyles.prices_title}>
            {t('howWeWork')} <span className='span'>{t('we')}</span>
          </h3>
        </div>
        <div className='row justify-content-center gy-5 gx-3 mb-5'>
          <div className={`${aboutStyles.card_box} col-12 col-md-4`}>
            <div className={aboutStyles.card_img_box}>
              <img src={work1} alt={t('goldBars')} className='img cover' />
            </div>
            <p className={aboutStyles.card_title}>{t('ourPrinciple')}</p>
            <p className={aboutStyles.card_txt}>
              {t('ourPrincipleDesc')}
            </p>
          </div>
          <div className={`${aboutStyles.card_box} col-12 col-md-4`}>
            <div className={aboutStyles.card_img_box}>
              <img src={work2} alt={t('goldTarget')} className='img cover' />
            </div>
            <p className={aboutStyles.card_title}>{t('target')}</p>
            <p className={aboutStyles.card_txt}>
              {t('targetDesc')}
            </p>
          </div>
          <div className={`${aboutStyles.card_box} col-12 col-md-4`}>
            <div className={aboutStyles.card_img_box}>
              <img src={work3} alt={t('gold')} className='img cover' />
            </div>
            <p className={aboutStyles.card_title}>{t('ourVision')}</p>
            <p className={aboutStyles.card_txt}>
              {t('ourVisionDesc')}
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
  
  );
}

export default AboutUs;
