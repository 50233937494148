import React from "react";
import bannerStyles from './styles.module.css';
import i18n from "../../locales/i18n";

function AppBanner({ title, url }) {
    return (
        <div className={bannerStyles.banner_box} style={{ backgroundImage: `url(${url})` }} >
            <h1 className={i18n.language === "ar" ? bannerStyles.banner_title_ar : bannerStyles.banner_title_en}>
                {title}
            </h1>
        </div >
    );
}

export default AppBanner;
