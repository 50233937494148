import React, { useEffect, useRef, useState } from "react";
import GPStyles from "./styles.module.css";
import homeStyles from "../Home/styles.module.css";
import { arrowSquareLeft, arrowSquareRight, banner1, info } from "../../assets";
import { CategoryScale, Chart } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { AppBanner, ProductItem } from "../../components";
import { scroll } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllProducts,
  getGoldPrice,
  getGoldTypes,
  getMarginValue,
} from "../../redux/slices/productsSlice";
import BalanceSection from "../GoldPriceHistory/BalanceSection";
import { t } from "i18next";

Chart.register(CategoryScale);

function GoldPrice() {
  const { loading, allProducts, goldTypesData, localGoldPrice, marginValue } =
    useSelector((state) => state.products);
  const margin = marginValue?.margin
  const productsRef = useRef(null);
  const intervalId = useRef(null);
  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "June"];
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "",
        backgroundColor: "#FCC65B80",
        borderColor: "#A7833F",
        data: [2020, 2030, 2020, 2060, 2040, 2050],
        fill: false,
      },
    ],
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllProducts());
    const reFetchData = () => {
      dispatch(GetAllProducts());
    };
    intervalId.current = setInterval(reFetchData, 30000);
    return () => clearInterval(intervalId.current);
  }, []);

  useEffect(() => {
    dispatch(getGoldTypes());
    dispatch(getGoldPrice());
    dispatch(getMarginValue());
  }, [dispatch]);

  const widgetRef = useRef(null);
  const [widgetInitialized, setWidgetInitialized] = useState(false);

  useEffect(() => {
    if (widgetRef.current && !widgetInitialized) {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        "autosize": true,
        "symbol": "OANDA:XAUUSD*FX_IDC:USDEGP",
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "dark",
        "style": "1",
        "locale": "en",
        "hide_legend": true,
        "allow_symbol_change": false,
        "save_image": false,
        "calendar": false,
        "support_host": "https://www.tradingview.com"
      });
      widgetRef.current.appendChild(script);
      setWidgetInitialized(true)
    }
  }, [widgetRef, widgetInitialized]);

  return (
    <main className="bg_dark">
      <div className="mt-lg-4 mb-5">
        <AppBanner url={banner1} title={t("goldPrice")} />
        <div>
          <BalanceSection />
        </div>
      </div>
      {/* eg_prices_section */}
      {/* <section className={GPStyles.eg_prices_section}>
        <div className="container">
          <div className={GPStyles.prices_title_box}>
            <h3 className={GPStyles.prices_title}>
              local gold price today <span className="span">in</span> egypt
            </h3>
          </div>
          <table className={GPStyles.eg_prices_table}>
            <thead>
              <tr>
                <th>type</th>
                <th>local gold 21</th>
                <th>local gold 24</th>
                <th>local gold pound</th>
                <th>local gold ounce</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={GPStyles.table_tag}>sell</td>
                <td>{(goldTypesData?.gold[0]._21k - margin).toFixed(2)} egp</td>
                <td>{(goldTypesData?.gold[0]._24k - margin).toFixed(2)} egp</td>
                <td>{((goldTypesData?.gold[0]._21k - margin) * 8).toFixed(2)} egp</td>
                <td>{((goldTypesData?.gold[0]._24k - margin) * 28.35).toFixed(2)} egp</td>
              </tr>
              <tr>
                <td className={GPStyles.table_tag}>buy</td>
                <td>{((goldTypesData?.gold[0]._21k) + margin).toFixed(2)} egp</td>
                <td>{(goldTypesData?.gold[0]._24k + margin).toFixed(2)} egp</td>
                <td>{((goldTypesData?.gold[0]._21k + margin) * 8).toFixed(2)} egp</td>
                <td>{((goldTypesData?.gold[0]._24k + margin) * 28.35).toFixed(2)} egp</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section> */}
      {/* charts_section */}
      <section>
        <div className="container">
          <div className={GPStyles.prices_title_box}>
            <h3 className={GPStyles.prices_title}>
              {t("localGoldPrice")}
            </h3>
          </div>
          <div className="mb-5" ref={widgetRef} style={{ height: '38rem', width: '100%' }} />
          {/* <div className={GPStyles.charts_container}>
            <div
              className={`${GPStyles.chart_upper} d-flex justify-content-between align-items-center`}
            >
              <div>
                <p className={GPStyles.charts_title}>
                  international gold price chart
                </p>
                <p className={GPStyles.charts_txt}>
                  gold price <span className="span">per</span> ounce
                </p>
              </div>
              <div className={GPStyles.charts_icon_box}>
                <img src={info} alt="help info" className="img" />
              </div>
            </div>
            <div className={`${GPStyles.line_chart_box}`}>
              <Line
                data={chartData}
                className={GPStyles.line_chart}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        color: "#FCC65B50",
                        tickBorderDash: [0.01, 0.01],
                      },
                      ticks: {
                        color: "#FCC65B80",
                      },
                    },
                    y: {
                      grid: {
                        color: "#FCC65B50",
                        tickBorderDash: [0.01, 0.01],
                      },
                      ticks: {
                        color: "#FCC65B",
                        callback: function (value) {
                          return value;
                        },
                      },
                      min: 2010,
                      max: 2070,
                    },
                  },
                }}
              />
            </div>
          </div> */}
        </div>
      </section>
      {/* products */}
      <section className={`${homeStyles.products} mb-5`}>
        <div className="container">
          <div className={homeStyles.trends_title_box}>
            <h3 className={`${homeStyles.trends_title} mb-1`}>
              {t("productsSelection")}
            </h3>
          </div>
          <div
            className={`${homeStyles.products_box} d-flex`}
            ref={productsRef}
          >
            {allProducts?.map((item) => (
              <ProductItem item={item} key={item.id} />
            ))}
          </div>
          <div className={homeStyles.products_btns_box}>
            <div
              onClick={() => scroll(-70, productsRef)}
              className={homeStyles.products_direction}
            >
              <img src={arrowSquareLeft} alt="arrow left" className="img" />
            </div>
            <div
              onClick={() => scroll(70, productsRef)}
              className={homeStyles.products_direction}
            >
              <img src={arrowSquareRight} alt="arrow right" className="img" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default GoldPrice;
