import React, { useState } from "react";
import { InputField, Button, SendOtp } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import loginStyles from "../Login/styles.module.css";
import styles from "./styles.module.css";
import {
  forgetPassword,
  sendReseetPasswordValidData,
  verifyOTPWithNumber,
} from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

function ForgetPassword() {
  const { loading, error, OTPError } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errormessage, setErrormessage] = useState("");
  const [formData, setFormData] = useState({
    phone: "",
  });
  const [showOtp, setShowOtp] = useState(false); // State for OTP visibility
  const [otp, setOtp] = useState(""); // State to store generated OTP
  // console.log(otp);
  const handleInputChange = (event) => {
    setErrormessage("");
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateData = () => {
    const phoneNumberRegex = /^(010|011|012|015)\d{8}$/;

    if (!formData.phone.trim()) {
      setErrormessage("Phone number is required.");
      return false;
    }

    if (!phoneNumberRegex.test(formData.phone)) {
      setErrormessage("Invalid phone number format.");
      return false;
    }

    setErrormessage("");
    return true;
  };

  const confirmRegister = () => {
    const data = {
      phone: `+2${formData.phone}`,
    };

    if (!validateData()) {
      return;
    }

    dispatch(forgetPassword(data))
      .unwrap()
      .then((response) => {
        const generatedOtp = response.otp; // Assuming the API returns the OTP
        setOtp(generatedOtp); // Store OTP in state
        setShowOtp(true); // Show OTP component
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };
  const handleOtpSubmit = (enteredOtp) => {
    const OTPNumber = {
      otp: String(enteredOtp), // Use entered OTP
      phone: `+2${formData.phone}`,
    };
    console.log("OTPNumber to send:", OTPNumber);

    if (showOtp) {
      dispatch(sendReseetPasswordValidData(OTPNumber));
      dispatch(verifyOTPWithNumber(OTPNumber))
        .unwrap()
        .then((response) => {
          console.log("Success response from server:", response);
          setTimeout(() => {
            navigate("/resetPassword");
          }, 1000);
        })
        .catch((error) => {
          console.error("Error during OTP submission:", error);
          console.error("Full error object:", error);
          // setErrormessage(error?.message || "OTP submission failed.");
        });
    }
  };
  return (
    <main>
    <div className="container pt-5">
      <div className="row justify-content-center">
        <div
          className={`${loginStyles.login_content} col-11 col-md-7 col-lg-5 ps-4 pe-3 pb-3`}
        >
          <p className={loginStyles.login_txt}>{t("forgetPassword")}</p>
          <div className={`${styles.phone_code_parent}`}>
            <p className={`${styles.phone_code} ${loginStyles.rem_txt}`}>+2</p>
            <InputField
              type={"text"}
              fieldName={"phone"}
              label={t("phone")}
              placeholder={t("enterYourPhone")}
              style={loginStyles.field_box}
              inputStyle={`${loginStyles.input} ${styles.phone_input}`}
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
          {errormessage && (
            <p
              className={`${loginStyles.rem_txt} ${loginStyles.errorText} ms-2 mb-3`}
            >
              {errormessage}
            </p>
          )}
          {error && (
            <p
              className={`${loginStyles.rem_txt} ${loginStyles.errorText} ms-2 mb-3`}
            >
              {error}
            </p>
          )}
          {showOtp && (
            <div>
              <SendOtp
                phone={`+2${formData.phone}`}
                navigation={"/login"}
                error={OTPError}
                onOtpComplete={(enteredOtp) => {
                  handleOtpSubmit(enteredOtp); // Dispatch the OTP number
                }}
              />
            </div>
          )}
          <Button
            title={t("getPassword")}
            style={loginStyles.login_btn}
            onClick={confirmRegister}
            loading={loading}
          />
        </div>
      </div>
    </div>
  </main>
  
  );
}

export default ForgetPassword;
