import React, { useEffect, useRef, useState } from "react";
import detailsStyles from "./styles.module.css";
import cartStyles from "../Cart/styles.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  arrowSquareLeft,
  arrowSquareRight,
  checkCircle,
  compareArrows,
  mastercard,
  minus,
  navFavorite,
  plus,
  priceDollar,
  share,
  starEmpty,
  starFull,
  trash,
  valu,
  visa,
} from "../../assets";
import { Button, InputField, MayLikeItem, ProductItem } from "../../components";
import { LIKESDATA } from "../../utils/data";
import { handleWheel, scroll } from "../../utils/functions";
import {
  AddToCart,
  GetAllProducts,
  GetProductDetails,
  uploadUserIdImages,
} from "../../redux/slices/productsSlice";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import homeStyles from "../Home/styles.module.css";
import { t } from "i18next";
import i18n from "../../locales/i18n";
import Slider from "react-slick"; // Import react-slick for the image slider

const Rate = ({ stars = 0 }) => {
  const STARS_NUMBER = 5;
  const rateStars = Math.min(Math.max(Number(stars), 0), STARS_NUMBER);
  const fullStar = <img src={starFull} alt="Full Star" className="ps-1" />;
  const emptyStar = <img src={starEmpty} alt="Empty Star" className="ps-1" />;
  let STARS = Array(STARS_NUMBER).fill(emptyStar);
  STARS.fill(fullStar, 0, rateStars);
  return <div className="mb-3">{STARS}</div>;
};

function ProductDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state;
  const [currentId, setCurrentId] = useState(null);
  const [count, setCount] = useState(1);
  const [isRemoved, setIsRemoved] = useState(false);
  const productsRef = useRef(null);
  const likesRef = useRef(null);
  const intervalId = useRef(null);
  const dispatch = useDispatch();

  const { loading, productDetails, allProducts } = useSelector(
    (state) => state.products
  );

  const [isSliderOpen, setIsSliderOpen] = useState(false); // State to toggle the slider modal
  const [currentIndex, setCurrentIndex] = useState(0); // State to track the current image index

  const openSlider = (index) => {
    setCurrentIndex(index);
    setIsSliderOpen(true);
  };

  const closeSlider = () => {
    setIsSliderOpen(false);
  };

  // Slick Slider settings
  const sliderSettings = {
    initialSlide: currentIndex,
    infinite: true,
    dots: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleCompare = () => {
    navigate("/compare", { state: { productDetails } });
  };
  console.log(productDetails?.images);
  useEffect(() => {
    setCurrentId(item?.id);
    dispatch(GetProductDetails(item?.id));
  }, [item]);

  const handleProductSelect = (selectedId) => {
    setCurrentId(selectedId);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(GetAllProducts());
  }, []);

  useEffect(() => {
    if (currentId) {
      dispatch(GetProductDetails(currentId));
    }
    const reFetchData = () => {
      if (currentId) {
        dispatch(GetProductDetails(currentId));
      }
    };
    intervalId.current = setInterval(reFetchData, 30000);
    return () => clearInterval(intervalId.current);
  }, [currentId]);

  const MySwal = withReactContent(Swal);

  const CustomForm = () => {
    const [formData, setFormData] = useState({
      frontImage: null,
      backImage: null,
    });

    const handleFileInputChange = (event) => {
      const { name } = event.target;
      const value = event.target.files[0];
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
    const confirmRegister = () => {
      dispatch(uploadUserIdImages(formData))
        .unwrap()
        .then(() => {
          sweetalertHandler(
            0,
            "Images uploaded successfully, pending verification"
          );
        })
        .catch((err) => {
          console.error("upload images error:", err);
        });
    };
    return (
      <div>
        <InputField
          type={"file"}
          fieldName={"frontImage"}
          label={"national id front"}
          placeholder={"no file selected"}
          value={formData.frontImage?.name}
          onChange={handleFileInputChange}
          style={`align-items-start`}
        />
        <InputField
          type={"file"}
          fieldName={"backImage"}
          label={"national id back"}
          placeholder={"no file selected"}
          value={formData.backImage?.name}
          onChange={handleFileInputChange}
          style={`align-items-start`}
        />
        <Button
          title={"upload"}
          style={detailsStyles.login_btn}
          onClick={confirmRegister}
          loading={loading}
        />
      </div>
    );
  };

  const sweetalertHandler = (errNumber, message) => {
    MySwal.fire({
      title: message,
      html: errNumber == 1 ? <CustomForm /> : null,
      showConfirmButton: errNumber == 1 ? false : true,
      confirmButtonText: errNumber == 2 ? "Login" : "OK",
      preConfirm:
        errNumber == 2
          ? () => {
              navigate("/login");
            }
          : null,
      customClass: {
        popup: "my_popup",
        title: "my_title",
        confirmButton: "my_content",
      },
      showClass: {
        popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                `,
      },
      hideClass: {
        popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                `,
      },
    });
  };

  const handleAddToCart = () => {
    const data = {
      product_id: currentId,
      quantity: count,
    };
    dispatch(AddToCart(data))
      .unwrap()
      .then(() => {
        navigate("/cart");
      })
      .catch((err) => {
        console.log("add to cat err", err);
        const errorMessage = err || err.message;
        if (errorMessage?.includes("upload")) {
          sweetalertHandler(1, errorMessage);
        } else if (errorMessage?.includes("Unauthenticated")) {
          sweetalertHandler(2, errorMessage);
        } else {
          sweetalertHandler(0, errorMessage);
        }
      });
  };

  return (
    <main>
      {/* details section */}
      <section className="my-5">
        <div className="container">
          <div className="row gy-4">
            <div
              className={`col-12 col-md-5 flex-md-grow-1 ${
                i18n.language === "ar" ? "ms-md-4 ms-lg-5" : "me-md-4 me-lg-5"
              }`}
            >
              <div
                className={`${detailsStyles.main_img_box} col-12 mb-3 py-2 px-5`}
              >
                <img
                  src={item?.image}
                  alt="trejars"
                  className="img c-pointer"
                  // onClick={() => openSlider(0)} // Open slider with the main image
                />
              </div>
              <div className="container-fluid">
                <div className="row column-gap-3">
                  <div
                    className={`${detailsStyles.mini_img_box} col-3 flex-grow-1 py-3`}
                    // onClick={() => openSlider(1)} // Open slider with the first mini image
                  >
                    <img
                      src={
                        productDetails?.images?.length > 0
                          ? productDetails?.images[0]?.image
                          : item?.image
                      }
                      alt="trejars"
                      className="img c-pointer"
                    />
                  </div>
                  <div
                    className={`${detailsStyles.mini_img_box} col-3 flex-grow-1 py-3`}
                    // onClick={() => openSlider(2)} // Open slider with the second mini image
                  >
                    <img
                      src={
                        productDetails?.images?.length > 1
                          ? productDetails?.images[1]?.image
                          : item?.image
                      }
                      alt="trejars"
                      className="img c-pointer"
                    />
                  </div>
                  <div
                    className={`${detailsStyles.mini_img_box} col-3 flex-grow-1 py-3`}
                    // onClick={() => openSlider(3)} // Open slider with the third mini image
                  >
                    <img
                      src={
                        productDetails?.images?.length > 2
                          ? productDetails?.images[2]?.image
                          : item?.image
                      }
                      alt="trejars"
                      className="img c-pointer"
                    />
                  </div>
                </div>
              </div>

              {/* Slider Modal */}
              {isSliderOpen && (
                <div className={`${detailsStyles.slider_modal}`}>
                  <div
                    className={` ${detailsStyles.slider_overlay}`}
                    onClick={closeSlider}
                  ></div>
                  <div className={`${detailsStyles.slider_content}`}>
                    <Slider
                      {...sliderSettings}
                      className={detailsStyles.Slider_Stylee}
                    >
                      {productDetails?.images?.map((image, index) => (
                        <div key={index} className={detailsStyles.img_content}>
                          <img
                            src={image?.image || item?.image}
                            alt={`slide-${index}`}
                            className="img-fluid"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 d-none d-md-block">
              <p className={`${detailsStyles.product_name} mb-2`}>
                {productDetails?.product?.name}
              </p>
              <Rate stars={productDetails?.product?.stars} />
              <div className="d-flex align-items-center">
                <img
                  src={checkCircle}
                  alt="check circle"
                  className={i18n.language === "ar" ? "ms-3" : "me-3"}
                />
                <p className={`${detailsStyles.small_txt} pt-3`}>
                  {t("making_and_stamping_fees_included")}
                </p>
              </div>
              <div className="row align-items-center mb-3">
                {loading ? (
                  <div className={`col-3 flex-grow-1 me-2 ps-lg-5`}>
                    <ThreeDots
                      visible={true}
                      height="63"
                      width="50"
                      color="#A7833F"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                ) : (
                  <p
                    className={`${detailsStyles.price} col-3 flex-grow-1 me-2 pt-3`}
                  >{`${productDetails?.product?.priceAfter?.toFixed(
                    2
                  )} egp`}</p>
                )}
                {isRemoved ? (
                  <Button
                    title={t("buy_now")}
                    style={`${detailsStyles.cart_btn} col-3 flex-grow-1 me-2 py-2`}
                  />
                ) : (
                  <div
                    className={`${detailsStyles.counter_box} d-flex justify-content-center col-3 flex-grow-1 me-2`}
                  >
                    <div
                      className={detailsStyles.trash_box}
                      onClick={
                        count > 1
                          ? () => setCount(count - 1)
                          : () => setIsRemoved(true)
                      }
                    >
                      <img
                        src={count > 1 ? minus : trash}
                        alt="trash"
                        className="img"
                      />
                    </div>
                    <div className={detailsStyles.num_box}>
                      <p className={detailsStyles.num}>{count}</p>
                    </div>
                    <div
                      className={detailsStyles.plus_box}
                      onClick={() =>
                        setCount(
                          count !== productDetails.product.stock
                            ? count + 1
                            : count
                        )
                      }
                    >
                      <img src={plus} alt="plus" className="img" />
                    </div>
                  </div>
                )}

                <Button
                  onClick={handleAddToCart}
                  title={t("add_to_cart")}
                  style={`${detailsStyles.cart_btn} col-3 flex-grow-1 py-2`}
                  loading={loading}
                />
              </div>
              <p className={`${detailsStyles.data_title} text-center`}>
                {count === productDetails?.product?.stock
                  ? t("no_more_data_available")
                  : ""}
              </p>

              <p className={`${detailsStyles.small_txt}`}>
                {t("prices_are_subject_to_change")}
              </p>
              <div className="container-fluid">
                <div className="row align-items-center mb-3">
                  <div className="col-3 d-flex justify-content-center me-xl-1 flex-grow-1 flex-xl-grow-0">
                    <img src={visa} alt="visa logo" className="me-2" />
                    <img
                      src={mastercard}
                      alt="mastercard logo"
                      className="me-2"
                    />
                    <img src={valu} alt="valu logo" />
                  </div>
                  <div className="col-3 d-flex justify-content-center me-2 me-xl-0 flex-grow-1 flex-xl-grow-0">
                    <img
                      src={priceDollar}
                      alt="price dollar"
                      className={`${detailsStyles.pointer} ${
                        i18n.language === "ar" ? "ms-2" : "me-2"
                      }`}
                    />
                    <img
                      src={navFavorite}
                      alt="heart"
                      className={`${detailsStyles.pointer} ${
                        i18n.language === "ar" ? "ms-2" : "me-2"
                      }`}
                    />
                    <img
                      src={share}
                      alt="share logo"
                      className={detailsStyles.pointer}
                    />
                  </div>
                  <div
                    className={`${detailsStyles.pointer} col-3 d-flex align-items-center justify-content-center`}
                    onClick={handleCompare}
                  >
                    <img
                      src={compareArrows}
                      alt="left right arrows"
                      className={`pb-1 ${
                        i18n.language === "ar" ? "ms-2" : "me-2"
                      }`}
                    />
                    <p className={`${detailsStyles.compare} pt-2`}>
                      {t("compare")}
                    </p>
                  </div>
                </div>
              </div>
              <p className={`${detailsStyles.availability} ps-2`}>
                {t("availability")}: {productDetails?.product?.Availability}
              </p>
              <div className="horizontal_line" />
            </div>
            <div className="col-12 col-md-6 d-block d-md-none px-4 align-items-center">
              <p className={`${detailsStyles.product_name} mb-4`}>
                {productDetails?.product?.name}
              </p>
              <div className="d-flex justify-content-between">
                <Rate stars={productDetails?.product?.stars} />
                <div
                  className={`${detailsStyles.pointer} d-flex align-items-center justify-content-center`}
                >
                  <img
                    src={compareArrows}
                    alt="left right arrows"
                    className="me-3 pb-2"
                  />
                  <p className={`${detailsStyles.compare}`}>compare</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <img src={checkCircle} alt="check circle" className="me-3" />
                <p className={`${detailsStyles.small_txt} pt-3`}>
                  making and stamping fees included
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <p
                  className={`${detailsStyles.price} pt-4`}
                >{`${productDetails?.product?.priceAfter?.toFixed(2)} egp`}</p>
                <div className="d-flex">
                  <img src={visa} alt="visa logo" className="me-2" />
                  <img
                    src={mastercard}
                    alt="mastercard logo"
                    className="me-2"
                  />
                  <img src={valu} alt="valu logo" />
                </div>
              </div>
              <p className={`${detailsStyles.small_txt}`}>
                Prices are subject to change based on egyptian instant market
                price
              </p>
              <div className="d-flex align-items-center justify-content-between mb-3">
                {isRemoved ? (
                  <Button
                    title={"buy now"}
                    style={`${detailsStyles.cart_btn} col-5 py-3`}
                  />
                ) : (
                  <div className={`${detailsStyles.counter_box} col-5 d-flex`}>
                    <div
                      className={detailsStyles.trash_box}
                      onClick={
                        count > 1
                          ? () => setCount(count - 1)
                          : () => setIsRemoved(true)
                      }
                    >
                      <img
                        src={count > 1 ? minus : trash}
                        alt="trash"
                        className="img"
                      />
                    </div>
                    <div className={detailsStyles.num_box}>
                      <p className={detailsStyles.num}>{count}</p>
                    </div>
                    <div
                      className={detailsStyles.plus_box}
                      onClick={() => setCount(count + 1)}
                    >
                      <img src={plus} alt="plus" className="img" />
                    </div>
                  </div>
                )}
                <Button
                  title={"add to cart"}
                  style={`${detailsStyles.cart_btn} col-5 py-3`}
                />
              </div>
              <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                  <p className={`${detailsStyles.availability} pt-3`}>
                    availability: {productDetails?.product?.Availability}
                  </p>
                  <div className="d-flex">
                    <img
                      src={priceDollar}
                      alt="price dollar"
                      className={`${detailsStyles.pointer} me-2`}
                    />
                    <img
                      src={navFavorite}
                      alt="heart"
                      className={`${detailsStyles.pointer} me-2`}
                    />
                    <img
                      src={share}
                      alt="share logo"
                      className={detailsStyles.pointer}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* description and reviews section */}
      <section>
        <div className="horizontal_line d-none d-md-block" />
        <div className="container">
          <div className="d-flex justify-content-center mt-5 mb-2">
            <p
              className={`${detailsStyles.active} ${
                i18n.language === "ar" ? "ms-4" : "me-4"
              } me-4`}
            >
              {t("description")}
            </p>
            <p className={`${detailsStyles.not_active}`}>{t("reviews")}</p>
          </div>
          <div className="horizontal_line d-block d-md-none mb-5" />
          <div className={`${detailsStyles.des_section} px-3 pt-4 pb-3 mb-4`}>
            <p className={`${detailsStyles.description_title} pb-1 mb-4`}>
              {t("product_description")}
            </p>
            <p className={`${detailsStyles.description_txt}`}>
              {productDetails?.product?.description}
            </p>
          </div>
          <div className={`${detailsStyles.des_section} px-3 pt-4 pb-3 mb-4`}>
            <p className={`${detailsStyles.description_title} pb-1 mb-4`}>
              {t("item_information")}
            </p>
            <div className="row">
              <p className={`${detailsStyles.data_title} col`}>{t("code")}</p>
              <p className={`${detailsStyles.data_txt} col`}>
                {productDetails?.product?.code || "NA"}
              </p>
            </div>
            <div className="row">
              <p className={`${detailsStyles.data_title} col`}>{t("color")}</p>
              <p className={`${detailsStyles.data_txt} col`}>
                {productDetails?.product?.color || "NA"}
              </p>
            </div>
            <div className="row">
              <p className={`${detailsStyles.data_title} col`}>{t("type")}</p>
              <p className={`${detailsStyles.data_txt} col`}>
                {productDetails?.product?.type || "NA"}
              </p>
            </div>
            <div className="row">
              <p className={`${detailsStyles.data_title} col`}>
                {t("age_group")}
              </p>
              <p className={`${detailsStyles.data_txt} col`}>
                {productDetails?.product?.age_group || "NA"}
              </p>
            </div>
            <div className="row">
              <p className={`${detailsStyles.data_title} col`}>
                {t("availability")}
              </p>
              <p className={`${detailsStyles.data_txt} col`}>
                {productDetails?.product?.Availability || "NA"}
              </p>
            </div>
            <div className="row mb-0">
              <p className={`${detailsStyles.data_title} col`}>
                {t("item_origin")}
              </p>
              <p className={`${detailsStyles.data_txt} col`}>
                {productDetails?.product?.item_origin || "NA"}
              </p>
            </div>
          </div>
          <div className={`${detailsStyles.des_section} px-3 pt-4 pb-3 mb-4`}>
            <p className={`${detailsStyles.description_title} pb-1 mb-4`}>
              {t("product_details")}
            </p>
            <div className="row mb-2">
              <p className={`${detailsStyles.data_title} col`}>{t("purity")}</p>
              <p className={`${detailsStyles.data_txt} col`}>
                {productDetails?.product?.purity || "NA"}
              </p>
            </div>
            <div className="row mb-2">
              <p className={`${detailsStyles.data_title} col`}>{t("stone")}</p>
              <p className={`${detailsStyles.data_txt} col`}>
                {productDetails?.product?.stone || "NA"}
              </p>
            </div>
            <div className="row mb-2">
              <p className={`${detailsStyles.data_title} col`}>{t("weight")}</p>
              <p className={`${detailsStyles.data_txt} col`}>
                {productDetails?.product?.Weight || "NA"} Gm
              </p>
            </div>
            <div className="row mb-2">
              <p className={`${detailsStyles.data_title} col`}>
                {t("dimension")}
              </p>
              <p className={`${detailsStyles.data_txt} col`}>
                {productDetails?.product?.dimension || "NA"}
              </p>
            </div>
          </div>
        </div>
        <div className="horizontal_line d-none d-md-block" />
      </section>
      {/* products */}
      <section className={`${homeStyles.products} mb-5`}>
        <div className="container">
          <div className={homeStyles.trends_title_box}>
            <h3 className={`${homeStyles.trends_title} mb-1`}>
              {t("productsSelection")}
            </h3>
          </div>
          <div
            className={`${homeStyles.products_box} d-flex`}
            ref={productsRef}
          >
            {allProducts?.map((item) => (
              <ProductItem
                item={item}
                key={item?.id}
                onSelect={() => handleProductSelect(item?.id)}
              />
            ))}
          </div>
          <div className={homeStyles.products_btns_box}>
            <div
              onClick={() => scroll(-70, productsRef)}
              className={homeStyles.products_direction}
            >
              <img src={arrowSquareLeft} alt="arrow left" className="img" />
            </div>
            <div
              onClick={() => scroll(70, productsRef)}
              className={homeStyles.products_direction}
            >
              <img src={arrowSquareRight} alt="arrow right" className="img" />
            </div>
          </div>
        </div>
      </section>
      {/* may_like */}
      <section className={cartStyles.may_like}>
        <div className="container">
          <p className={cartStyles.may_title}>{t("youMayLike")}</p>
          <div
            className={`${cartStyles.likes_box} d-flex mt-0`}
            onWheel={(event) => handleWheel(event, likesRef)}
            ref={likesRef}
          >
            {LIKESDATA.map((item) => (
              <MayLikeItem item={item} />
            ))}
          </div>
        </div>
      </section>
    </main>
  );
}

export default ProductDetails;
