import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import historyStyles from "../styles.module.css";
import { t } from "i18next";

function OrderDetailsModal({ orderDetailsdata, onClose }) {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className={styles.modal_parent}>
      <div className={styles.order_modal_content} ref={modalRef}>
        <div className="d-flex align-items-center justify-content-between">
          <p className="dash_title mb-5 text-center">
            {t("OrderID")}: {orderDetailsdata?.order?.id}
          </p>
          <p className="dash_title mb-5 text-center">
            {t("Totalamount")}: {orderDetailsdata?.order?.amount}
          </p>
        </div>
        <div className="row">
          <p className={`${historyStyles.table_title} col-2`}>
            {t("ProductID")}
          </p>
          <p className={`${historyStyles.table_title} col-1`}>
            {t("Quantity")}
          </p>
          <p className={`${historyStyles.table_title} col-3`}>
            {t("NameofProduct")}
          </p>
          <p className={`${historyStyles.table_title} col-2`}>{t("productPrice")}</p>
          <p className={`${historyStyles.table_title} col-2`}>{t("TotalPrice")}</p>
          <p className={`${historyStyles.table_title} col-2`}>{t("Status")}</p>
        </div>
        {orderDetailsdata.cart.map((order) => (
          <div className="row my-4" key={order?.product?.id}>
            <p className={`${historyStyles.table_data} col-2`}>
              {order?.product?.id}
            </p>
            <p className={`${historyStyles.table_data} col-1`}>
              {order?.quantity}
            </p>
            <p className={`${historyStyles.table_data} col-3`}>
              {order?.product?.name}
            </p>
            <p className={`${historyStyles.table_data} col-2`}>
              {order?.product?.price}
            </p>
            <p className={`${historyStyles.table_data} col-2`}>
              {order?.product?.totalPrice}
            </p>
            <p className={`${historyStyles.table_data} col-2`}>
              {orderDetailsdata?.order?.status}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OrderDetailsModal;
