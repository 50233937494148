// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import ar from "./ar.json";
const resources = {
  en: { translation: en },
  ar: { translation: ar },
};

const savedLanguage = localStorage.getItem("language") || "en";

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage, 
  fallbackLng: "en", 
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
