import React, { useEffect, useState } from "react";
import navStyles from "./styles.module.css";
import {
  chevronRight,
  close,
  navCart,
  navFavorite,
  navLogo,
  navMenu,
  navSearch,
  navUser,
} from "../../assets";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Button } from "../";
import { useDispatch, useSelector } from "react-redux";
import { searchForProduct } from "../../redux/slices/productsSlice";
import i18n from "../../locales/i18n";
import { t } from "i18next";

function Nav({ isDashboardOpen, isDashMenuOpen, setIsDashMenuOpen }) {
  const [searchValue, setSearchValue] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const { token, user } = useSelector((state) => state.auth);
  const savedLanguage = localStorage.getItem("language") || "en";
  const [language, setLanguage] = useState(savedLanguage);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate("/shop");
    window.scrollTo(0, 0);
    dispatch(searchForProduct(searchValue));
  };

  const isMobile = useMediaQuery({ maxWidth: "767px" });

  // =============================== Start Translation Button ===============================
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    localStorage.setItem("language", lng); // Save selected language to localStorage
    document.documentElement.dir = lng === "ar" ? "rtl" : "ltr"; // Handle RTL dynamically
  };

  useEffect(() => {
    changeLanguage(savedLanguage); // Set language on initial load
  }, [savedLanguage]);
  // =============================== End Translation Button===============================

  useEffect(() => {
    if (!isMobile) {
      setShowMenu(false);
    }
  }, [isMobile]);

  return (
    <nav className={`${navStyles.nav_Container}`}>
      <div className="container">
        <div
          className={`${navStyles.language_Line} row justify-content-between align-items-start py-2`}
        >
          <div className="col c-pointer">
            {i18n.language === "en" ? (
              <p
                className={`${navStyles.language} ${
                  language === "en" ? navStyles.active : ""
                }`}
                // onClick={() => changeLanguage("ar")}
              >
                English
              </p>
            ) : (
              <p
                className={`${navStyles.language} ${
                  language === "ar" ? navStyles.active : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                العربية
              </p>
            )}
          </div>

          <div className="d-flex col justify-content-end">
            <Link
              to={token ? "/dashboard" : "/login"}
              className={`${navStyles.my_account} d-none d-sm-block`}
            >
              {user ? user?.Fname : t("myAccount")}
            </Link>
            <Link to={token ? "/dashboard" : "/login"} state={5}>
              <img
                src={navFavorite}
                alt="heart-icon"
                className={
                  i18n.language === "ar"
                    ? navStyles.nav_small_icon_ar
                    : navStyles.nav_small_icon_en
                }
              />
            </Link>
            <Link to={token ? "/dashboard" : "/login"} state={2}>
              <img
                src={navUser}
                alt="account-icon"
                className={
                  i18n.language === "ar"
                    ? navStyles.nav_small_icon_ar
                    : navStyles.nav_small_icon_en
                }
              />
            </Link>
            <Link to={token ? "cart" : "/login"}>
              <img
                src={navCart}
                alt="cart-icon"
                className={
                  i18n.language === "ar"
                    ? navStyles.nav_small_icon_ar
                    : navStyles.nav_small_icon_en
                }
              />
            </Link>
          </div>
        </div>
      </div>
      <div className={`${navStyles.nav_Box}`}>
        <div className="container">
          <div className="row justify-content-between align-items-center gx-5">
            <figure
              className={`${navStyles.nav_log_Box} col-4 col-md-2 col-xlg-1`}
            >
              <Link to="Home">
                <img
                  src={navLogo}
                  alt="site-Logo"
                  className={navStyles.nav_logo}
                />
              </Link>
            </figure>
            <ul className="my-md-0 justify-content-md-between align-items-md-center d-none d-md-flex col-md-6 flex-md-grow-1 flex-xlg-grow-0 list-unstyled">
              <li>
                <NavLink
                  to="home"
                  className={({ isActive }) =>
                    isActive ? navStyles.active_link : navStyles.nav_txt
                  }
                >
                  {t("home")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="shop"
                  className={({ isActive }) =>
                    isActive ? navStyles.active_link : navStyles.nav_txt
                  }
                >
                  {t("shop")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="goldPrice"
                  className={({ isActive }) =>
                    isActive ? navStyles.active_link : navStyles.nav_txt
                  }
                >
                  {t("goldPrice")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="aboutUs"
                  className={({ isActive }) =>
                    isActive ? navStyles.active_link : navStyles.nav_txt
                  }
                >
                  {t("aboutUs")}
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="goldPriceHistory"
                  className={({ isActive }) =>
                    isActive ? navStyles.active_link : navStyles.nav_txt
                  }
                >
                  news
                </NavLink>
              </li> */}
            </ul>
            <div
              className={`d-none d-md-block col-md-3 col-xlg-2 ms-lg-4 ${navStyles.search_box_container}`}
            >
              <div
                className={`${navStyles.search_box} d-none d-md-flex ps-2 pe-3 px-lg-3`}
              >
                <input
                  type="text"
                  className={`${navStyles.search_input} col-11`}
                  placeholder={t("searchProducts")}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
                <button
                  className={`${navStyles.search_btn} col-1`}
                  onClick={handleSearch}
                >
                  <img
                    src={navSearch}
                    alt="search-icon"
                    className={navStyles.small_Img}
                  />
                </button>
              </div>
            </div>
            <div
              className={`${navStyles.nav_toggle} ${
                showMenu ? navStyles.open_toggle : navStyles.nav_toggle
              } d-md-none col-2`}
              onClick={() => {
                setShowMenu(!showMenu);
                setIsDashMenuOpen(!isDashMenuOpen);
              }}
            >
              <img src={navMenu} alt="burger menu" className="img" />
            </div>
          </div>
          {/* mobile screen navbar */}
          {showMenu && !isDashboardOpen && (
            <div
              className={`${navStyles.menu} px-5 py-4 w-100 d-flex flex-column`}
            >
              <div
                className={navStyles.close_box}
                onClick={() => {
                  setShowMenu(false);
                  setIsDashMenuOpen(false);
                }}
              >
                <img src={close} alt="close" className="img" />
              </div>
              <div className={`${navStyles.search_box_container} my-4`}>
                <div
                  className={`${navStyles.search_box} w-100 d-flex ps-2 pe-3`}
                >
                  <input
                    type="text"
                    className={`${navStyles.search_input} col-11`}
                    placeholder="search products"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <button
                    className={`${navStyles.search_btn} col-1`}
                    onClick={handleSearch}
                  >
                    <img
                      src={navSearch}
                      alt="search-icon"
                      className={navStyles.small_Img}
                    />
                  </button>
                </div>
              </div>
              <p className={navStyles.menu_txt}>main menu</p>
              <ul className="mb-4 mt-4 list-unstyled d-flex flex-column align-items-center">
                <li
                  className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}
                >
                  <NavLink
                    to="home"
                    className={navStyles.nav_txt}
                    onClick={() => {
                      setShowMenu(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    home
                  </NavLink>
                  <div className={navStyles.chevron_box}>
                    <img
                      src={chevronRight}
                      alt="chevron right"
                      className="img"
                    />
                  </div>
                </li>
                <li
                  className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}
                >
                  <NavLink
                    to="goldPrice"
                    className={navStyles.nav_txt}
                    onClick={() => {
                      setShowMenu(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    gold price
                  </NavLink>
                  <div className={navStyles.chevron_box}>
                    <img
                      src={chevronRight}
                      alt="chevron right"
                      className="img"
                    />
                  </div>
                </li>
                <li
                  className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}
                >
                  <NavLink
                    to="shop"
                    className={navStyles.nav_txt}
                    onClick={() => {
                      setShowMenu(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    shop
                  </NavLink>
                  <div className={navStyles.chevron_box}>
                    <img
                      src={chevronRight}
                      alt="chevron right"
                      className="img"
                    />
                  </div>
                </li>
                <li
                  className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}
                >
                  <NavLink
                    to="aboutUs"
                    className={navStyles.nav_txt}
                    onClick={() => {
                      setShowMenu(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    about us
                  </NavLink>
                  <div className={navStyles.chevron_box}>
                    <img
                      src={chevronRight}
                      alt="chevron right"
                      className="img"
                    />
                  </div>
                </li>
                <li
                  className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}
                >
                  <NavLink
                    to="contactUs"
                    className={navStyles.nav_txt}
                    onClick={() => {
                      setShowMenu(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    contact us
                  </NavLink>
                  <div className={navStyles.chevron_box}>
                    <img
                      src={chevronRight}
                      alt="chevron right"
                      className="img"
                    />
                  </div>
                </li>
                <li
                  className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}
                >
                  {/* <NavLink
                    to="goldPriceHistory"
                    className={navStyles.nav_txt}
                    onClick={() => {
                      setShowMenu(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    news
                  </NavLink> */}
                  <div className={navStyles.chevron_box}>
                    <img
                      src={chevronRight}
                      alt="chevron right"
                      className="img"
                    />
                  </div>
                </li>
                <li
                  className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}
                >
                  <NavLink
                    to={token ? "/dashboard" : "/login"}
                    className={navStyles.nav_txt}
                    onClick={() => {
                      setShowMenu(false);
                      window.scrollTo(0, 0);
                    }}
                  >
                    {user ? user?.Fname : "my account"}
                  </NavLink>
                  <div className={navStyles.chevron_box}>
                    <img
                      src={chevronRight}
                      alt="chevron right"
                      className="img"
                    />
                  </div>
                </li>
              </ul>
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  title={"login"}
                  to={"login"}
                  onClick={() => {
                    setShowMenu(false);
                    window.scrollTo(0, 0);
                  }}
                  style={`${navStyles.secondry_btn} px-4 py-1 me-4`}
                />
                <Button
                  title={"sign up"}
                  to={"signUp"}
                  style={`px-4 py-1`}
                  onClick={() => {
                    setShowMenu(false);
                    window.scrollTo(0, 0);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Nav;
