import React, { useState } from "react";
import loginStyles from "./styles.module.css";
import {
  apple,
  checkBox,
  checkedBox,
  eye,
  eyeSlash,
  facebookCircle,
  google,
  loginBanner,
} from "../../assets";
import { Button, InputField } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../../redux/slices/authSlice";
import i18n from "../../locales/i18n";
import { t } from "i18next";

function Login() {
  const [isChecked, setIsChecked] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state.auth);

  const handleInputChange = (event) => {
    setError(false);
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const isValidEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const isValidPassword = (password) => {
    return password.length >= 8;
  };

  const confirmEmail = () => {
    if (!isValidEmail(formData.email) || !isValidPassword(formData.password)) {
      setError(true);
    }
  };
  const confirmLogin = () => {
    const data = {
      login: formData.email,
      password: formData.password,
    };

    confirmEmail();

    if (isValidEmail(formData.email) && isValidPassword(formData.password)) {
      dispatch(LoginUser(data))
        .unwrap()
        .then(() => {
          navigate("/home");
        })
        .catch((err) => {
          console.log("Error during login:", err);
          setError(true);
        });
    }
  };

  return (
    <main>
      <div className="container pt-5 mt-5">
        <div className="row justify-content-center g-lg-0">
          <div
            className={`${loginStyles.login_img} d-none d-lg-block col-lg-8`}
          >
            <img src={loginBanner} alt="gold planet" className="img cover" />
          </div>
          <div
            className={`${loginStyles.login_content} col-11 col-md-7 col-lg-4 justify-content-center ps-4 pe-3 pb-3`}
          >
            <p className={loginStyles.login_txt}>{t("login")}</p>
            <p className={loginStyles.welcome_txt}>{t("trejars")}</p>
            <p className={loginStyles.welcome_txt}>{t("welcomeBack")}</p>
            <InputField
              type={"text"}
              fieldName={"email"}
              label={t("email")}
              placeholder={t("enterEmail")}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.email}
              onChange={handleInputChange}
            />
            <InputField
              type={!isPasswordVisible ? "password" : "text"}
              fieldName={"password"}
              label={t("password")}
              placeholder={t("enterYourPassword")}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.password}
              onChange={handleInputChange}
              rightIcon={
                isPasswordVisible ? (
                  <img src={eye} alt="eye" className="img" />
                ) : (
                  <img src={eyeSlash} alt="eye-slash" className="img" />
                )
              }
              onRightIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
            />
            {error ? (
              <p
                className={`${loginStyles.rem_txt} ${loginStyles.errorText} ms-2 mb-3`}
              >
                {t("invalidEmailOrPassword")}
              </p>
            ) : null}

            <div className="d-flex justify-content-between align-items-center px-2 mb-3">
              <div
                className="d-flex align-items-center c-pointer mb-3"
                onClick={() => setIsChecked(!isChecked)}
              >
                {isChecked ? (
                  <img src={checkedBox} alt="checked box" />
                ) : (
                  <img src={checkBox} alt="check box" />
                )}
                <p className={`${loginStyles.rem_txt} ${i18n.language === "ar" ? "me-2" : "ms-2"} mb-0`}>
                  {t("rememberMe")}
                </p>
              </div>
              <p>
                <Link
                  to={"/forgetPassword"}
                  className={`${loginStyles.forgot_txt} mb-0`}
                >
                  {t("forgotYourPassword")}
                </Link>
              </p>
            </div>
            <Button
              title={t("login")}
              style={loginStyles.login_btn}
              onClick={confirmLogin}
              loading={loading}
            />
            <p className={loginStyles.login_with_txt}>{t("orLoginWith")}</p>
            <div className="d-flex justify-content-center mb-3">
              <div className={loginStyles.icon_box}>
                <img src={apple} alt="apple" className="img cover" />
              </div>
              <div className={loginStyles.icon_box}>
                <img src={google} alt="gmail" className="img cover" />
              </div>
              <div className={loginStyles.icon_box}>
                <img
                  src={facebookCircle}
                  alt="facebook"
                  className="img cover"
                />
              </div>
            </div>
            <p className={loginStyles.sign_txt}>
              {t("dontHaveAccount")}{" "}
              <Link to={"/signUp"} className={loginStyles.sign_txt2}>
                {t("letsSignUp")}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
