import React, { useEffect, useState } from "react";
import dashStyles from "../Dashboard/styles.module.css";
import accountStyles from "./styles.module.css";
import {
  accountGoldBars,
  accountShipCar,
  avatar,
  checkCircle,
  goldBar50,
  lockClockGold,
} from "../../assets";
import { } from "../../components";
import OrderHistory from "../OrderHistory";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../redux/slices/authSlice";
import { t } from "i18next";
import i18n from "../../locales/i18n";

function Account({ pageID, activePageID, activeIdHandler }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserDetails(user.id));
  }, [dispatch]);

  const { user, userInfo } = useSelector((state) => state.auth);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (activePageID === pageID) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [activePageID]);

  // Completed Order
  const completedOrders = userInfo?.orders.map((order) => {
    return order;
  });

  return (
    <div
    className={`${isActive ? dashStyles.active_container : dashStyles.in_active_container} col-11 col-md-8 flex-md-grow-1`}
  >
    <div className="row">
      <div className={`${accountStyles.account_data_box} d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start mb-md-2 py-3`}>
        <div className={accountStyles.account_img_box}>
          <img
            src={user?.photo ? user.photo : avatar}
            alt="profile img"
            className="img"
          />
        </div>
        <div className="d-flex flex-column align-items-center align-items-md-start ms-md-3 mt-3 mt-md-0">
          <p className={`${accountStyles.hello_txt} mb-0`}>
            {t("hello")}, {user?.Fname}
          </p>
          <p className={`${accountStyles.welcome_txt} mb-0`}>
            {t("welcomeProfile")}
          </p>
        </div>
      </div>
      <div className="horizontal_line d-none d-md-block" />
    </div>
    <div className="row flex-column flex-md-row">
      <div
        className={`${accountStyles.address_box} gray_box col-12 col-md-5 flex-md-grow-1 col-lg px-3 py-3 me-md-2 mb-3 mb-md-0 order-1`}
      >
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className={accountStyles.address_name}>{t("personalInformation")}</p>
          <p
            className={accountStyles.edit_address}
            onClick={() => activeIdHandler(2)}
          >
            {t("edit")}
          </p>
        </div>
        <div className="row align-items-center justify-content-between mt-3">
          <p className={`${accountStyles.address_key} col-3 mb-0`}>{t("name")}:</p>
          <p className={`${accountStyles.address_value} col-8 mb-0`}>
            {user?.Fname}
          </p>
        </div>
        <div className="row align-items-center justify-content-between mt-3">
          <p className={`${accountStyles.address_key} col-3 mb-0`}>{t("email")}:</p>
          <p className={`${accountStyles.address_value} col-8 mb-0`}>
            {user?.email ? user.email : t("pleaseAddEmail")}
          </p>
        </div>
        <div className="row align-items-center justify-content-between mt-3">
          <p className={`${accountStyles.address_key} col-3 mb-0`}>{t("phone")}:</p>
          <p className={`${accountStyles.address_value} col-8 mb-0`}>
            {user?.phone ? user.phone : t("pleaseAddPhone")}
          </p>
        </div>
        <div className="row align-items-center justify-content-between mt-3">
          <p className={`${accountStyles.address_key} col-3 mb-0`}>{t("city")}:</p>
          <p className={`${accountStyles.address_value} col-8 mb-0`}>
            {user?.city ? user.city : t("pleaseAddCity")}
          </p>
        </div>
        <div className="row align-items-center justify-content-between mt-3">
          <p className={`${accountStyles.address_key} col-3 mb-0`}>{t("zip")}:</p>
          <p className={`${accountStyles.address_value} col-8 mb-0`}>
            {user?.id}
          </p>
        </div>
      </div>
    </div>
    <div className="row flex-column flex-md-row my-4">
      <div className={`gray_box d-flex flex-column align-items-center col-12 col-md-3 flex-md-grow-1 mb-4 mb-md-0 ${i18n.language === "ar" ? "ms-md-4" : "me-md-4"}  py-3`}>
        <div className={`${accountStyles.served_img_box} p-1`}>
          <img src={checkCircle} alt="checked circle" className="img" />
        </div>
        <p className={`${accountStyles.served_name} my-3`}>{t("orders")}</p>
        <p className={`${accountStyles.served_number} mb-0`}>
          {userInfo?.ordersCount ? userInfo.ordersCount : 0}
        </p>
      </div>
      <div className={`gray_box d-flex flex-column align-items-center col-12 col-md-3 flex-md-grow-1 mb-4 mb-md-0 ${i18n.language === "ar" ? "ms-md-4" : "me-md-4"}  py-3`}>
        <div className={accountStyles.served_img_box}>
          <img src={accountShipCar} alt="van car" className="img" />
        </div>
        <p className={`${accountStyles.served_name} my-3`}>
          {t("deliveryCompleted")}
        </p>
        <p className={`${accountStyles.served_number} mb-0`}>
          {userInfo?.deliveryCompleted ? userInfo.deliveryCompleted : 0}
        </p>
      </div>
      <div className="gray_box d-flex flex-column align-items-center col-12 col-md-3 flex-md-grow-1 py-3">
        <div className={accountStyles.served_img_box}>
          <img src={lockClockGold} alt="timer lock" className="img" />
        </div>
        <p className={`${accountStyles.served_name} my-3`}>{t("storedItems")}</p>
        <p className={`${accountStyles.served_number} mb-0`}>
          {userInfo?.storedItem ? userInfo.storedItem : 0}
        </p>
      </div>
    </div>
  
    <div className="row">
      <OrderHistory styles={`col-12 col-md-12 mt-4`} />
    </div>
  </div>
  
  );
}

export default Account;
