import './App.css';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
  AboutUs,
  Cart,
  Checkout,
  Compare,
  ContactUs,
  Dashboard,
  ForgetPassword,
  GoldPrice,
  GoldPriceHistory,
  Home,
  Login,
  OrderConfirm,
  PrivacyPolicy,
  ProductDetails,
  Shop,
  SignUp,
} from './pages';
import { Footer, Nav } from './components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import ResetPassword from './pages/ResetPassword';


function ScrollToTop({ setIsDashboardOpen }) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/dashboard') {
      setIsDashboardOpen(true);
    } else {
      setIsDashboardOpen(false);
    }

    window.scrollTo(0, 0);
  }, [pathname, setIsDashboardOpen]);

  return null;
}

function App() {
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [isDashMenuOpen, setIsDashMenuOpen] = useState(false);

  return (
    <div className='bg_dark hidden'>
      <Nav isDashboardOpen={isDashboardOpen} isDashMenuOpen={isDashMenuOpen} setIsDashMenuOpen={setIsDashMenuOpen} />
      <ScrollToTop setIsDashboardOpen={setIsDashboardOpen} />
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path='signUp' element={<SignUp />} />
        <Route path='forgetPassword' element={<ForgetPassword/>} />
        <Route path='resetPassword' element={<ResetPassword/>} />
        <Route path='login' element={<Login />} />
        <Route path='home' element={<Home />} />
        <Route path='goldPrice' element={<GoldPrice />} />
        <Route path='shop' element={<Shop />} />
        <Route path='aboutUs' element={<AboutUs />} />
        {/* <Route path='goldPriceHistory' element={<GoldPriceHistory />} /> */}
        <Route path='cart' element={<Cart />} />
        <Route path='contactUs' element={<ContactUs />} />
        <Route path='productDetails' element={<ProductDetails />} />
        <Route path='checkout' element={<Checkout />} />
        <Route path='orderConfirm' element={<OrderConfirm />} />
        <Route path='compare' element={<Compare />} />
        <Route path='dashboard' element={<Dashboard isDashMenuOpen={isDashMenuOpen} setIsDashMenuOpen={setIsDashMenuOpen} />} />
        <Route path='privacyPolicy' element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
