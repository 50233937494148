import React, { useEffect, useState } from "react";
import dashStyles from "./styles.module.css";
import accountStyles from "../Account/styles.module.css";
import { persistor } from "../../redux/store"; // Import the persistor
import { useDispatch, useSelector } from "react-redux";

import {
  Account,
  Address,
  ChangePassword,
  OrderHistory,
  PaymentMethod,
  PersonalInfo,
  WishList,
} from "..";
import { useLocation, useNavigate } from "react-router-dom";
import {
  avatar,
  dashboard,
  dashboardBlack,
  dashboardCart,
  dashboardCartBlack,
  dashboardCredit,
  dashboardCreditBlack,
  dashboardLocation,
  dashboardLocationBlack,
  dashboardLock,
  dashboardLockBlack,
  dashboardUser,
  dashboardUserBlack,
  favoriteFull,
  favoriteFullBlack,
  logout,
  logoutBlack,
  trejars,
} from "../../assets";
import { useMediaQuery } from "react-responsive";
import { logoutSuccess } from "../../redux/slices/authSlice";
import { GetOrderDetails } from "../../redux/slices/productsSlice";
import i18n from "../../locales/i18n";
import { t } from "i18next";

function Dashboard({ isDashMenuOpen, setIsDashMenuOpen }) {
  const dispatch = useDispatch();
  const { OrderDetails } = useSelector((state) => state.products);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const pageID = location.state ?? 1;
  const [activeID, setActiveID] = useState(-1);
  //
  const isMobile = useMediaQuery({ maxWidth: "767px" });
  //
  const logoutHandler = () => {
    // Clear localStorage data
    localStorage.removeItem("auth");
    localStorage.removeItem("contact");
    localStorage.removeItem("products");
    localStorage.removeItem("_persist");
    localStorage.clear();

    // Dispatch the logout action to reset auth state in Redux
    dispatch(logoutSuccess());

    // Purge the persisted state to clear all Redux-persist data
    persistor.purge();

    // Redirect to the login page
    navigate("/login");
  };
  const activeIdHandler = (id) => {
    setActiveID(id);
  };
  //
  useEffect(() => {
    dispatch(GetOrderDetails({ user_id: user?.id }));
  }, [dispatch]);
  //
  const SCREENS = [
    {
      id: 1,
      name: t("dashboard"),
      page: (
        <Account
          pageID={1}
          activePageID={activeID}
          activeIdHandler={activeIdHandler}
        />
      ),
      activeImg: dashboardBlack,
      inActiveImg: dashboard,
      alt: "dashboard logo",
    },
    {
      id: 2,
      name: t("personalInfo"),
      page: (
        <PersonalInfo
          pageID={2}
          activePageID={activeID}
          activeIdHandler={activeIdHandler}
        />
      ),
      activeImg: dashboardUserBlack,
      inActiveImg: dashboardUser,
      alt: "user logo",
    },

    {
      id: 3,
      name: t("orderHistory"),
      page: <OrderHistory pageID={3} activePageID={activeID} />,
      activeImg: dashboardCartBlack,
      inActiveImg: dashboardCart,
      alt: "shopping-cart logo",
    },

    // { id: 6, name: 'Gift', page: <Gift pageID={6} activePageID={activeID} />, activeImg: dashboardGiftBlack, inActiveImg: dashboardGift, alt: 'gift logo' },
    {
      id: 4,
      name: t("address"),
      page: <Address pageID={4} activePageID={activeID} />,
      activeImg: dashboardLocationBlack,
      inActiveImg: dashboardLocation,
      alt: "location logo",
    },
    {
      id: 5,
      name: t("changePassword"),
      page: <ChangePassword pageID={5} activePageID={activeID} />,
      activeImg: dashboardLockBlack,
      inActiveImg: dashboardLock,
      alt: "lock logo",
    },
    // {
    //   id: 6,
    //   name: "Payment method",
    //   page: <PaymentMethod pageID={6} activePageID={activeID} />,
    //   activeImg: dashboardCreditBlack,
    //   inActiveImg: dashboardCredit,
    //   alt: "credit-card logo",
    // },
    // {
    //   id: 7,
    //   name: "Wishlist",
    //   page: <WishList pageID={7} activePageID={activeID} />,
    //   activeImg: favoriteFullBlack,
    //   inActiveImg: favoriteFull,
    //   alt: "heart logo",
    // },
  ];

  useEffect(() => {
    setActiveID(pageID);
  }, []);

  return (
    <main>
      <div className="container">
        <div className="row my-5 align-items-start justify-content-center">
          <div
            className={`d-none d-md-block col-md-3 ${
              i18n.language === "ar" ? "ms-md-3" : "me-md-3"
            }  gray_box px-lg-3 pt-4 pb-3`}
          >
            <div
              className={`${dashStyles.logo_box} mb-4 ps-lg-3 d-none d-md-block`}
            >
              <img src={trejars} alt="trejars" className="img" />
            </div>
            <div
              className={`${accountStyles.account_data_box} d-md-none d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start mb-md-2 py-3`}
            >
              <div className={accountStyles.account_img_box}>
                <img src={avatar} alt="profile img" className="img" />
              </div>
              <div className="d-flex flex-column align-items-center align-items-md-start ms-md-3 mt-3 mt-md-0">
                <p className={`${accountStyles.hello_txt} mb-0`}>
                  hello, ahmed
                </p>
                <p className={`${accountStyles.welcome_txt} mb-0`}>
                  Welcome to your profile...
                </p>
              </div>
            </div>
            {/**********************************/}
            {SCREENS.map((screen) => {
              return (
                <div
                  key={screen.id}
                  className={`${
                    screen.id === activeID
                      ? dashStyles.active_box
                      : dashStyles.in_active_box
                  } px-2 px-lg-2 py-2 mt-3 d-flex align-items-center`}
                  onClick={() => setActiveID(screen.id)}
                >
                  <div
                    className={`${dashStyles._dash_title_logo} ${
                      i18n.language === "ar" ? "ms-2" : "me-2"
                    }`}
                  >
                    <img
                      src={
                        screen.id === activeID
                          ? screen.activeImg
                          : screen.inActiveImg
                      }
                      alt="trejars"
                      className="img"
                    />
                  </div>
                  <p
                    className={`${
                      screen.id === activeID
                        ? dashStyles.active_name
                        : dashStyles.in_active_name
                    } mb-0`}
                  >
                    {screen.name}
                  </p>
                </div>
              );
            })}
            {/********************************* */}
            <div
              className={`${
                activeID === 9
                  ? dashStyles.active_box
                  : dashStyles.in_active_box
              } px-3 px-lg-2 py-2 mt-3 d-flex align-items-center`}
              onClick={logoutHandler}
            >
              <div
                className={`${dashStyles._dash_title_logo} ${
                  i18n.language === "ar" ? "ms-2" : "me-2"
                }`}
              >
                <img
                  src={activeID === 9 ? logoutBlack : logout}
                  alt="logout logo"
                  className="img"
                />
              </div>
              <p
                className={`${
                  activeID === 9
                    ? dashStyles.active_name
                    : dashStyles.in_active_name
                } mb-0`}
              >
                {t("logout")}
              </p>
            </div>
          </div>
          {isDashMenuOpen && isMobile && (
            <div className={`${dashStyles.dash_menu} gray_box px-sm-5 py-4`}>
              <div
                className={`${accountStyles.account_data_box} d-md-none d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-start mb-md-2 py-3`}
              >
                <div className={accountStyles.account_img_box}>
                  <img src={avatar} alt="profile img" className="img" />
                </div>
                <div className="d-flex flex-column align-items-center align-items-md-start ms-md-3 mt-3 mt-md-0">
                  <p className={`${accountStyles.hello_txt} mb-0`}>
                    hello, ahmed
                  </p>
                  <p className={`${accountStyles.welcome_txt} mb-0`}>
                    Welcome to your profile...
                  </p>
                </div>
              </div>
              {SCREENS.map((screen) => {
                return (
                  <div
                    key={screen.id}
                    className={`${
                      screen.id === activeID
                        ? dashStyles.active_box
                        : dashStyles.in_active_box
                    } px-2 px-lg-2 py-2 mt-3 d-flex align-items-center`}
                    onClick={() => {
                      setActiveID(screen.id);
                      setIsDashMenuOpen(false);
                    }}
                  >
                    <div className={`${dashStyles._dash_title_logo} me-2`}>
                      <img
                        src={
                          screen.id === activeID
                            ? screen.activeImg
                            : screen.inActiveImg
                        }
                        alt="trejars"
                        className="img"
                      />
                    </div>
                    <p
                      className={`${
                        screen.id === activeID
                          ? dashStyles.active_name
                          : dashStyles.in_active_name
                      } mb-0`}
                    >
                      {screen.name}
                    </p>
                  </div>
                );
              })}
              <div
                className={`${
                  activeID === 9
                    ? dashStyles.active_box
                    : dashStyles.in_active_box
                } px-3 px-lg-2 py-2 mt-3 d-flex align-items-center`}
                onClick={() => {
                  setActiveID(9);
                  setIsDashMenuOpen(false);
                }}
              >
                <div className={`${dashStyles._dash_title_logo} me-2`}>
                  <img
                    src={activeID === 9 ? logoutBlack : logout}
                    alt="logout logo"
                    className="img"
                  />
                </div>
                <p
                  className={`${
                    activeID === 9
                      ? dashStyles.active_name
                      : dashStyles.in_active_name
                  } mb-0`}
                >
                  {t("logout")}
                </p>
              </div>
            </div>
          )}
          {SCREENS[activeID - 1]?.page}
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
